<template>
  <div class="Payment">
    <div class="info">
      <div class="money"><span class="symbol">¥</span>{{ data.AMT }}</div>
      <div class="cell">
        <span class="label">订单创建时间：</span
        ><span class="text">{{ data.CREATE_DATE }}</span>
      </div>
      <div class="cell">
        <span class="label">商家订单编号：</span
        ><span class="text">{{ data.ORDERID }}</span>
      </div>
      <div class="cell">
        <span class="label">钱包余额：</span
        ><span class="text">{{ data.ACC_BAL }}</span>
      </div>
      <div class="cell">
        <span class="label"
          ><span class="iconfont icon-a-iconpay_huaban1fuben38"></span
          >倒计时：</span
        ><span class="text"
          ><van-count-down
            :time="Number(data.Second) * 1000"
            @finish="onFinish"
        /></span>
      </div>
    </div>

    <van-dialog
      v-model:show="show"
      title="请输入支付密码"
      show-cancel-button
      @confirm="confirm"
    >
      <van-field
        v-model="draw_password"
        type="password"
        placeholder="请输入支付密码"
      />
    </van-dialog>

    <van-button class="submit" type="primary" @click="show = true"
      >立即付款</van-button
    >
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { PayGetUserInfo, payorder } from "@/api";
import { Dialog } from "vant";
import md5 from "js-md5";

export default {
  setup() {
    const state = reactive({
      data: {},
      show: false,
      draw_password: "",
    });

    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    state.data = route.query;

    console.log(12123, state.data);

    PayGetUserInfo().then((res) => {
      if (res.status) {
        state.data.ACC_BAL = res.data.ACC_BAL;
      } else {
        Dialog.alert({
          message: res.message,
        });
      }
    });

    const confirm = () => {
      payorder({
        draw_password: md5(state.draw_password),
        content: state.data.ID,
      }).then((res) => {
        if (res.status) {
          Dialog.alert({
            message: res.message,
          }).then(() => router.replace("/Home"));
        } else {
          Dialog.alert({
            message: res.message,
          });
        }
      });
    };

    const onFinish = () => {
      Dialog.alert({
        message: "订单已超时！",
      }).then(() => router.replace("/Home"));
    };

    return {
      ...toRefs(state),
      router,
      confirm,
      onFinish,
    };
  },
};
</script>

<style lang="scss" scoped>
.Payment {
  background: $background;
  text-align: center;

  .info {
    width: h(346);
    padding: 0 h(15);
    margin: 0 auto;
    box-sizing: border-box; 
  }

  .money {
    margin: 0 auto h(12);
    padding-top: h(80);
    font-weight: bold;
    font-size: 28px;
    text-align: center;
    color: #3366ff;

    .symbol {
      font-size: 18px;
    }
  }

  .cell {
    display: flex;
    justify-content: space-between;
    padding: h(13) 0;
    box-sizing: border-box;
    margin: 0 auto;
    font-size: 14px;

    &:nth-child(4) {
      border-bottom: 1px solid #e0e0e0;
    }

    .label {
      color: #828282;

      .iconfont {
        vertical-align: middle;
        margin-right: h(4);
      }
    }

    .text {
      color: #000000;

      .van-count-down {
        color: #ff3b30;
      }
    }
  }

  .submit {
    margin: h(45) auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: h(10);

    position: static;
    width: h(315);
    height: h(40);

    background: #3366ee;
    border-radius: h(4);
  }
}
</style>
